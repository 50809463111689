<template>
  <div class="navbar-container main-menu-content">
    <horizontal-nav-menu-items :items="availableMenu" />
  </div>
</template>

<script>
import { appMenuItems } from '@/router'
import HorizontalNavMenuItems from './components/horizontal-nav-menu-items/HorizontalNavMenuItems.vue'

// ! TODO enabled permission menu
export default {
  components: {
    HorizontalNavMenuItems,
  },
  setup() {
    return {
      navMenuItems: appMenuItems,
    }
  },
  computed: {
    availableMenu() {
      // ซ่อนเมนูตาม permission
      // const routeFilter = (routes) => routes
      //   .map((route) => route.children 
      //     ? { ...route, children: routeFilter(route.children) }
      //     : route
      //   )
      //   .filter((route) => { 
      //     return this.$allowPermission(route.key)});
      // return routeFilter(this.navMenuItems)
      return this.navMenuItems
    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/horizontal-menu.scss";
</style>
